.email-page {
    background-color: white;
    padding: 10px;
    margin: 15px 50px 25px 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ececec;
    // max-width: 1400px;

    .editor-container {
        margin: 25px 25px 25px 25px;
        border-radius: 10px;
        background-color: white;
        border: 1px solid $table-borders;
    }

    .result-container {
        margin: 25px 25px 25px 25px;
        border-radius: 10px;
    }

    .result {
        min-width: 100%;
        height: 250px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 2px solid $secondary;
        border-radius: 4px;
        background-color: #f8f8f8;
        font-size: 16px;
        resize: none;
    }

    .copy-button {
        margin-bottom: 25px;
    }
    .save-button {
        margin-left: 10px;
    }

    .separator {
        margin-right: 12px;
    }

    .demo-editor {
        min-height: 400px;
        padding: 0px 25px;
        background: #f8fafa;
    }
    .validation-loader {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: $box-shadow;
        z-index: 100;
    }

    .success {
        color: $success-text;
    }
    .error {
        color: $error-text;
    }
}
