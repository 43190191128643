.checkbox-container {
    width: 100%;

    .checkbox-label {
        display: block;
        font-size: 16px;
        width: 70%;
        word-break: break-all;
        position: relative;
        top: 2px;
    }

    .checkbox-input {
        width: 30%;
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        height: 20px;
        width: 20px;
        background-color: $primary;
        border: none;
        box-shadow: none;
        outline: 1.5px solid $checkbox-border;
        transition: 0.1s;
        background-repeat: no-repeat;
        background-position: center;
        image-rendering: -webkit-optimize-contrast;
        border-radius: 2px;

        &:after {
            position: absolute;
            display: block;
            left: 11px;
            top: 6px;
            width: 9px;
            height: 14px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:checked {
            background-color: $dark-turq;
            outline: 1.5px solid $primary;
            transition: 0.1s;
            background-image: url('../../images/check.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}
