.home-dashboard {
    background-color: white;
    padding: 25px;
    margin: 15px 50px 25px 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ececec;
    // max-width: 1400px;

    .header {
        margin-bottom: 25px;
        .title {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .statistics-block {
        background-color: white;
        border-radius: 20px;
        border: 1px solid $table-borders;
        padding: 25px;
        box-shadow: $box-shadow;
        height: 100%;
        max-height: 500px;

        .amount-text {
            font-size: 40px;
            padding: 25px;
            text-align: center;
        }

        .chart-static {
            margin: auto;
            position: relative;
            padding-top: 25px;

            canvas {
                width: auto;
                max-height: 300px;
            }
        }

        &.mr {
            margin-right: 10px;
        }
    }

    .sub-title {
        font-weight: bold;
    }

    .empty-state {
        display: block;
        text-align: center;
    }
}
