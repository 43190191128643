.alert-wrapper {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: $box-shadow;
    z-index: 100;

    .block {
        border-radius: 10px;
        background: $white;
        padding: 25px 0px 25px 25px;
        width: 350px;
        position: relative;
        display: block;
        text-align: left;

        @include lg {
            width: 450px;
        }

        .close-icon {
            position: absolute;
            right: 17px;
            top: 22px;

            svg {
                font-size: 26px;
            }

            &:hover {
                cursor: pointer;
                color: $dark-turq;

                svg {
                    color: $dark-turq;

                    path {
                        color: $dark-turq;
                    }
                }
            }
        }

        .title {
            display: block;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .sub {
            background-color: $light-grey;
            border-radius: 10px;
            border: 1px solid $secondary;
            margin-bottom: 25px;
            max-width: 95%;
            position: relative;

            .copy-icon {
                position: absolute;
                right: 40px;
                top: 20px;

                @include lg {
                    top: 20px;
                    right: 40px;
                }

                svg {
                    font-size: 26px;
                }

                &:hover {
                    cursor: pointer;
                    color: $dark-turq;

                    svg {
                        color: $dark-turq;

                        path {
                            color: $dark-turq;
                        }
                    }
                }
            }
        }

        .span-value {
            display: block;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 20px;
            margin-left: 20px;
            margin-top: 20px;
        }

        .successcopy {
            margin-bottom: 20px;
            margin-left: 6px;
        }

        .text {
            display: block;
            margin: 15px 10px 25px 0;
            white-space: pre-line;
            padding-right: 25px;
            max-height: 400px;
        }

        .btn-container {
            padding-right: 20px;
        }

        .btn-spacing {
            width: 20px;
        }
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: $white;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $scroll-bar-modal;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: $table-borders;
        }
    }
}
