$xs: "(min-width: 0px)";
$sm: "(min-width: 576px)";
$md: "(min-width: 768px)";
$lg: "(min-width: 992px)";
$lg-b: "(min-width: 1050px)";
$xl: "(min-width: 1200px)";
$xl-b: "(min-width: 1400px)";
$xxl: "(min-width: 1630px)";
$xxxl: "(min-width: 2500px)";
$xxxxl: "(min-width: 3000px)";

@mixin xs {
  @media #{$xs}  {
    @content;
  }
}

@mixin sm {
  @media #{$sm}  {
    @content;
  }
}

@mixin md {
  @media #{$md}  {
    @content;
  }
}

@mixin lg {
  @media #{$lg}  {
    @content;
  }
}

@mixin lg-b {
    @media #{$lg-b}  {
      @content;
    }
  }

@mixin xl {
    @media #{$xl}  {
      @content;
    }
}


@mixin xl-b {
    @media #{$xl-b}  {
      @content;
    }
}

@mixin xxl {
    @media #{$xxl}  {
      @content;
    }
}

@mixin xxxl {
    @media #{$xxxl}  {
      @content;
    }
}

@mixin xxxxl {
    @media #{$xxxxl}  {
      @content;
    }
}