.button {
    padding: 15px 31px;
    border-radius: 7px;
    text-decoration: none;
    transition: 0.2s;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    line-height: 16px;
    min-height: 46px;
    min-width: 130px;

    &:focus,
    &:active {
        outline: 0;
        border: 0;
    }

    &.fullwidth {
        width: 100%;
    }

    &.default {
        background: $bg-btn;
        transition: 0.2s;
        color: $white;
    }

    &:hover {
        cursor: pointer;
        background: $bg-btn-hover;
        transition: 0.2s;
        color: $white !important;
    }

    &.warning {
        background: $error-text;
        transition: 0.2s;
        color: $white;

        &:hover {
            cursor: pointer;
            background: lighten($error-text, 5);
            transition: 0.2s;
            color: $white !important;
        }
    }
    &.cancel {
        background: $white;
        transition: 0.2s;
        color: $font;

        &:hover {
            cursor: pointer;
            background: $light-grey;
            transition: 0.2s;
            color: $font !important;
        }
    }

    &.disabled {
        cursor: auto;
        filter: opacity(45%);
        background: $bg-btn-disabled;
        color: $font;

        &:hover {
            color: $font !important;
        }
    }

    &.icon-spacing {
        padding: 10px 31px 10px 18px;
    }

    .icon {
        line-height: 0px;
        margin-right: 5px;
        color: $white;

        svg {
            color: $white;
            path {
                color: $white;
            }
        }
    }

    &.alt {
        background: $white;
        transition: 0.2s;
        color: $bg-btn;
        border: 1px solid $bg-btn;

        .icon {
            svg {
                color: $bg-btn;
                path {
                    color: $bg-btn;
                }
            }
        }

        &:hover {
            color: $bg-btn-hover !important;
            background: $white;
            border: 1px solid $bg-btn-hover;
            transition: 0.2s;

            .icon {
                svg {
                    color: $bg-btn-hover;
                    path {
                        color: $bg-btn-hover;
                    }
                }
            }
        }
    }

    .spinner {
        position: relative;
        border: 4px solid rgba(255, 255, 255, 0.3);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border-left-color: $white;

        animation: spin 1s ease infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
