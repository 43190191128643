.page-header {
    height: 70px;
    border-bottom: 1px solid $table-borders;
    width: 100%;
    padding: 20px 50px 0 50px;

    .user-block {
        position: relative;
        border: 1px solid $table-borders;
        padding: 5px 10px;
        border-radius: 5px;
        background: white;
        user-select: none;
        display: block;

        &:hover {
            cursor: pointer;
        }

        .name {
            font-size: 13px;
            display: block;
            user-select: none;
        }
    }

    .lang-switch {
        margin-right: 10px;
        display: block;
        border: 1px solid $table-borders;
        padding: 5px 10px;
        border-radius: 5px;
        background: white;
        font-size: 13px;
        user-select: none;

        &:hover {
            cursor: pointer;
        }

        .lang-icon {
            font-size: 18px;
            margin-right: 5px;
        }
    }
}
