.btn-loader {
    display: block;

    .loader {
        border: 3px solid $secondary;
        border-top: 3px solid transparent;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
