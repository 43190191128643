//colors
$white: #fff;
$black: #000;
$light-grey: #ececec;
$med-grey: #e6e6e6;

$primary: #f5f9f9;
$secondary: #591fe2;
$tertiary: #5b48af;
$dark-turq: #7752fe;
$quaternary: #d8d4ff;

$mandatory-star: #d22c30;

//page bgs
$main-bg: #f7f7f7;
$login-bg: $primary;

$tooltip-bg: #616060;

//fonts
$font: #1f1e1e;
$font-alt: #7a7777;
$font-alt-2: #6d6d6d;
$font-alt-3: #9f9f9f;
$link: $dark-turq;
$link-hover: $dark-turq;
$font-disabled: #d2d2d2;
$success-text: #4d9d34;

//input
$input-text: $font;
$input-label: $font-alt-3;
$input-label-top: $dark-turq;
$input-disabled-bg: #f8f8f8;
$input-disabled-text: #7a7a7a;
$input-border: $med-grey;
$error-text: #a41f1f;

//button
$bg-btn-disabled: #e2e2e2;
$bg-btn: $dark-turq;
$bg-btn-hover: $tertiary;

//sidebar
$sidebar-bg: #d8d4ff;
$sidebar-font-active: rgba(18, 18, 18, 1);
$sidebar-font-inactive: rgba(18, 18, 18, 0.85);

//tables
$table-heading: #d8d4ff;
$table-borders: $light-grey;
$table-cols: $white;
$table-group: #f3f3f3;

//snackbar
$snackbar: $font;

//general
$box-shadow: 0px 0px 5px #ececec;

//checkbox
$checkbox-border: $table-borders;

//scrollbar modal
$scroll-bar-modal: #dddddd;
