//Fields
.select-field {
    position: relative;

    .select-wrapper {
        position: relative;

        .select {
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 100%;
            display: block;
            height: 45px;
            border-radius: 5px;
            border: 1px solid $input-border;
            padding: 0px 30px 0 15px;
            color: $input-text;
            transition: 0.1s;
            min-width: 170px;

            &:focus {
                outline: 0;
            }

            &.disabled {
                background: $input-disabled-bg;
            }

            &.clickable {
                background: $white;
                color: $font;
                min-width: 225px;

                .label {
                    display: block;
                    padding: 10px 0px;
                    position: relative;
                }

                .reset-filter {
                    position: absolute;
                    right: -17px;
                    top: 13px;

                    svg {
                        font-size: 18px;
                        color: $error-text;

                        path {
                            color: $error-text;
                        }
                    }

                    &:hover {
                        svg {
                            color: lighten($error-text, 30%);

                            path {
                                color: lighten($error-text, 30%);
                            }
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &.icon-spacing {
                padding-left: 40px;
            }
        }

        &.error {
            select {
                border: 1px solid $error-text;
            }
        }

        .arrow-icon {
            position: absolute;
            top: 11px;
            right: 5px;
            pointer-events: none;

            .icon {
                font-size: 13px;
            }
        }
    }

    .error-text {
        color: $error-text;
        font-size: 12px;
        position: relative;
        top: -6px;
        text-align: left;
        margin: 0 15px;
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 10px;
        pointer-events: none;

        svg {
            path {
                color: $input-label;
            }
        }
    }

    .calender-icon {
        position: relative;
        font-size: 24px;
        margin-right: 5px;

        svg {
            font-size: 24px;

            path {
                font-size: 24px;
            }
        }
    }
}
