body {
    overflow-x: hidden;
}

html,
body,
#root,
#root > div {
    height: 100%;
}

#root > div {
    display: flex;
    flex-flow: column;
    background-color: $main-bg;
}

.emptystate {
    background: $white;
    height: 100%;
}

.text-align-center {
    text-align: center;
}

.page-subtitle {
    font-size: 15px;
    margin: 15px 50px 25px 50px;
    border: 1px solid $table-borders;
    padding: 15px 25px;
    width: fit-content;
    border-radius: 10px;
    box-shadow: $box-shadow;

    .key {
        font-weight: bold;
    }

    .value {
        display: inline-block;
        margin-left: 10px;
    }
}

.sales-product-overview,
.sales-import-overview {
    @include lg {
        > .table-wrapper:first-child {
            height: 100%;
            width: 60% !important;
        }

        > .table-wrapper:last-child {
            height: 100%;
            width: 30%;

            .td-heading {
                width: 200px;
            }
        }
    }
}

.cal-days {
    .weekday {
        position: absolute;
        left: -53px;
        width: 44px;
        background: $table-borders;
        color: $font-alt-3;
        opacity: 0.8;
    }
    .inactive-day {
        color: $font-disabled;
        opacity: 0.8;
    }
    .today-active {
        font-weight: 400;
    }
    .today-line {
        width: 30px;
        height: 3px;
        color: $primary;
    }
    .selected-day {
        color: $white;
    }
}
.rdrDayDisabled .day {
    color: $font-disabled;
}
