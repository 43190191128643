$regular: 'TitilliumWeb-Regular';
$semibold: 'TitilliumWeb-SemiBold';
$bold: 'TitilliumWeb-Bold';
$italic: 'TitilliumWeb-Italic';
$light: 'TitilliumWeb-Light';

@font-face {
    font-family: $regular;
    src: url('../fonts/TitilliumWeb-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $semibold;
    src: url('../fonts/TitilliumWeb-SemiBold.ttf') format('ttf');
    font-weight: semibold;
    font-style: normal;
}
@font-face {
    font-family: $bold;
    src: url('../fonts/TitilliumWeb-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: $italic;
    src: url('../fonts/TitilliumWeb-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: $light;
    src: url('../fonts/TitilliumWeb-Light.ttf') format('ttf');
    font-weight: light;
    font-style: normal;
}

body,
* {
    font-family: $regular, sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: $font;
}

h1,
.h1 {
    display: block;
    font-size: 35px;
    font-weight: bold;
    line-height: 55px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h2,
.h2 {
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h3,
.h3 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h4,
.h4 {
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: $font;

    &.-alt {
        color: $font;
    }
}
