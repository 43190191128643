// table
.table-wrapper {
    background-color: $white;
    border-radius: 10px;
    box-shadow: $box-shadow;
    margin: 15px 50px 25px 50px;

    &.data-display {
        width: 90%;

        @include lg {
            width: 50%;

            &.md-large {
                width: 45%;
            }

            &.extra-large {
                width: 70%;
            }
        }
    }

    &.auto-height {
        height: 100%;
        margin: 135px 50px 25px 0px;
    }

    &.mid-size {
        max-width: 90%;

        @include lg {
            max-width: 60%;
        }

        @include xl {
            max-width: 50%;
        }

        @include xxl {
            max-width: 40%;
        }
    }

    &.mid-large-size {
        max-width: 90%;

        @include lg {
            max-width: 70%;
        }

        @include xl {
            max-width: 70%;
        }

        @include xxl {
            max-width: 50%;
        }
    }

    &.large-size {
        max-width: 90%;

        @include lg {
            max-width: 80%;
        }

        @include xl {
            max-width: 80%;
        }

        @include xxl {
            max-width: 70%;
        }
    }

    .table-header {
        padding: 15px 0px 15px 25px;
        min-height: 75px;

        &.filled {
            background: $table-heading;
        }

        .title {
            font-size: 18px;
            font-weight: 600;
        }

        .new-order-button {
            margin-right: 15px;
        }

        .export-button {
            margin-right: 15px;
        }
    }

    .table {
        width: 100%;

        @include xl {
            .miniWidth {
                width: 50px;
            }

            .smallWidth {
                width: 100px;
            }

            .smWidth {
                width: 150px;
            }

            .midWidth {
                width: 200px;
            }

            .largeWidth {
                width: 300px;
            }

            .extraLargeWidth {
                width: 420px;
            }
        }

        th {
            padding: 10px 25px;
            background: $table-heading;
            border: 1px solid $table-borders;
            border-bottom: 2px solid $table-borders;
            text-align: left;
            font-weight: bold;
            user-select: none;

            &.incl-actions {
                width: 80px;
                padding: 10px 15px;
            }
        }

        .empty-space {
            height: 30px;
        }

        tr {
            border: 1px solid $table-borders;
        }

        td {
            border: 1px solid $table-borders;
            padding: 12px 25px;
            background-color: $table-cols;
            vertical-align: middle;
            height: 50px;

            a {
                line-height: 0;

                &:hover {
                    transition: 0.2s;
                    color: $link;
                }
            }

            &.last {
                padding: 10px 10px;

                .actions {
                    div {
                        line-height: 0;
                    }
                    svg {
                        &:hover {
                            cursor: pointer;

                            path {
                                color: $tertiary;
                                transition: 0.2s;
                            }
                        }
                    }

                    .product-icon {
                        margin: 0 7px 0 5px;
                    }

                    .edit-icon {
                        margin-right: 7px;
                    }
                }
            }
        }

        &.sub-table {
            min-width: 600px;

            @include lg {
                .minWidth {
                    &.small {
                        width: 200px;
                    }

                    &.med {
                        width: 800px;
                    }

                    &.amount {
                        width: 700px;
                    }
                }
            }
        }

        .minp {
            padding: 10px;

            &.btn a {
                min-width: 160px;
            }
        }
    }

    .td-heading {
        width: 250px;
        padding: 12px 25px !important;

        &.vAlignTop {
            vertical-align: top;
        }
    }

    .td-inner {
        padding: 10px 10px !important;

        &.text-only {
            padding: 10px 15px !important;
        }

        &.datepicker-width .date-range-picker {
            width: 225px;
        }
    }

    .save-order-row {
        padding: 10px;

        svg {
            font-size: 20px;
        }
    }

    .actions {
        svg {
            &:hover {
                color: $tertiary;
                cursor: pointer;
            }
            path {
                &:hover {
                    color: $tertiary;
                    cursor: pointer;
                }
            }
        }
    }

    .add-row {
        margin-top: 10px;

        .input-field {
            width: 100%;
        }

        .new-row {
            a {
                margin-right: 0;
                margin-left: 10px;
                width: 210px;
                height: 45px;

                &:hover {
                    color: $white;
                }
            }

            .icon {
                svg {
                    font-size: 18px;
                }
            }
        }
    }

    .table-footer {
        padding: 30px 25px;
        color: $font-alt;
        text-align: center;
        user-select: none;

        .page-nav-block {
            width: 250px;
            user-select: none;

            button {
                -webkit-appearance: none;
                -moz-appearance: none;
                background: $white;
                border: 1px solid $table-borders;
                width: 40px;
                height: 36px;
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                user-select: none;

                svg {
                    font-size: 11px;
                }

                &:hover {
                    cursor: pointer;
                    background: $table-heading;
                }

                &:disabled {
                    svg {
                        color: $font-disabled;
                        path {
                            color: $font-disabled;
                        }
                    }

                    cursor: inherit;

                    &:hover {
                        background: $white;
                    }
                }

                &.last {
                    width: 60px;

                    svg {
                        width: 13px;

                        &:last-child {
                            position: relative;
                            left: -7px;
                            margin-right: -7px;
                        }
                    }
                }
            }
        }

        .page-block {
            padding: 5px 15px;
            display: inline-block;
            border: 1px solid $table-borders;
            font-size: 13px;
            margin: 0 5px;

            &.active {
                font-weight: bold;
                background: $table-heading;
            }

            &:hover {
                cursor: pointer;
                background: $table-heading;
            }
        }
    }

    .double-rows {
        table {
            width: 50%;

            &:last-child {
                margin-left: 15px;
            }
        }
    }

    .inner-table-group-heading {
        background: $table-group !important;
        height: 46px !important;
        padding: 10px 25px !important;
    }

    &.min-width {
        min-width: 600px;
    }
}

.general-info,
.detail {
    width: 100%;
    height: 100%;
    margin: 0px;

    &:first-child {
        margin-right: 25px;
    }

    .download-pdf {
        position: relative;
        margin-left: 5px;
        top: 2px;

        &:hover {
            cursor: pointer;
            color: $tertiary;

            path {
                color: $tertiary;
            }
        }
        &.disabled {
            path {
                color: $bg-btn-disabled;
            }

            &:hover {
                cursor: initial;
            }
        }
    }

    .status-text {
        display: block;
        margin-right: 10px;
    }

    .archive-icon {
        position: relative;
        top: 0px;
        display: inline-block;
        margin-right: 5px;

        &:hover {
            cursor: pointer;
            color: $tertiary;

            path {
                color: $tertiary;
            }
        }
    }

    .close-icon {
        position: relative;
        top: 0px;
        margin-right: 3px;

        &:hover {
            cursor: pointer;
            color: $tertiary;

            path {
                color: $tertiary;
            }
        }
    }

    .delete-icon {
        position: relative;
        top: 0px;
        display: inline-block;

        &:hover {
            cursor: pointer;
            color: $tertiary;

            path {
                color: $tertiary;
            }
        }
    }

    .email-icon,
    .settings-icon {
        position: relative;
        top: -2px;
        margin-left: 7px;

        &:hover {
            cursor: pointer;
            color: $tertiary;

            path {
                color: $tertiary;
            }
        }
        &.disabled {
            cursor: inherit;
            color: $bg-btn-disabled;

            path {
                color: $bg-btn-disabled;
            }
        }
    }

    .td-heading {
        width: 200px !important;

        @include lg {
            width: 250px !important;
        }
    }

    th {
        background: $white !important;
    }

    .space {
        width: 12px;
    }

    &.adjust-width {
        max-width: 70%;
    }

    &.fit-content {
        width: fit-content;
    }
}

.filters {
    color: $font-alt;
    margin-right: 15px;

    > div {
        margin-left: 15px;
    }

    .sorting {
        &.active {
            svg {
                color: $dark-turq;
                path {
                    color: $dark-turq;
                }
            }
        }
        &:hover {
            cursor: pointer;

            svg {
                color: $dark-turq;
                path {
                    color: $dark-turq;
                }
            }
        }
    }
}

.page-margins {
    margin: 15px 50px 25px 50px;
}

.w-100 {
    width: 100%;
}

.file-wrapper {
    margin-left: 12px;
    position: relative;

    &:hover {
        cursor: pointer;
        color: $tertiary;

        span {
            color: $tertiary;
        }

        svg {
            color: $tertiary;
            path {
                color: $tertiary;
            }
        }
    }

    .file-name {
        text-overflow: ellipsis;
        width: 90px;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
        margin-left: 5px;

        @include lg {
            width: 150px;
        }

        @include xl {
            width: 200px;
        }
    }
}

.remove-file-icon {
    position: relative;
    top: 3px;

    svg {
        color: $error-text;
        path {
            color: $error-text;
        }
    }

    &:hover {
        cursor: pointer;

        svg {
            color: $font;
            path {
                color: $font;
            }
        }
    }
}

.error-field {
    display: block;
    color: $error-text;
    font-size: 12px;
    position: relative;
    top: 0px;
    line-height: 16px;
}

.mantadory-explanation {
    margin-left: 15px;
    font-size: 13px;
    font-style: italic;
}

.mandatory-star {
    color: $mandatory-star;
}

.empty-select {
    display: block;
    font-size: 13px;
    line-height: 18px;
}

.date-range-picker {
    position: relative;

    .calendar-picker {
        background: $white;
        position: absolute;
        z-index: 99;
        border: 1px solid $table-borders;
        top: 50px;

        &.align-center {
            top: inherit;
            transform: translate(-18%, 2%);
        }

        .rdrDefinedRangesWrapper {
            display: none;
        }

        .rdrMonthAndYearPickers,
        .rdrWeekDay {
            text-transform: capitalize;
        }

        .rdrDayToday {
            span:after {
                background: $tertiary !important;
            }
        }

        .rdrNextPrevButton {
            background: $table-borders;

            &:hover {
                background: $secondary;
            }
        }
    }
}

.min-amount {
    color: $font;
    font-size: 12px;
    position: relative;
    top: 5px;
    left: 3px;
}

.supplier-amount {
    color: $font;
    font-size: 12px;
    position: relative;
    top: 5px;
    left: 3px;
    display: block;
}

.table.draggable {
    .th-sort {
        width: 50px !important;
        padding: 0px 35px;

        svg {
            position: relative;
            top: 6px;
        }
    }
    td {
        user-select: none;
    }
    .draggable {
        text-align: center;
        padding: 0px !important;

        svg {
            position: relative;
            top: 4px;
        }

        &:hover {
            cursor: pointer !important;

            svg {
                color: $dark-turq;
                path {
                    color: $dark-turq;
                }
            }
        }
    }
}

.detail-date-picker {
    padding: 12px 12px !important;
}

.search-filter {
    min-width: 120px;
}

.table-bottom {
    padding-bottom: 25px;
}
